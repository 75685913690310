<script setup lang="ts">
import {
  mdiCloudLock, mdiContentDuplicate,
  mdiDominoMask,
  mdiFormatAlignLeft,
  mdiFormTextbox, mdiGavel,
  mdiLaptop,
  mdiLockPlus, mdiMagnify,
  mdiShieldCheck, mdiCalendarMonth, mdiAccountGroup, mdiOfficeBuildingMarker, mdiEmail, mdiCellphoneMessage
} from "@mdi/js";
import HomeCard from "@/components/HomeCard.vue";
import TrialBanner from "@/components/TrialBanner.vue";
import GenericLayout from "@/layouts/GenericLayout.vue";

const securityParagraphs = [
  {
    icon: mdiShieldCheck,
    text: "Using Secure Clinic's <b>cloud electronic health record (EHR)</b> software makes this easy, letting you concentrate on running your business."
  },
  {
    icon: mdiDominoMask,
    text: 'Worry less about keeping your data safe from modern threats, including ransomware, phishing, data breaches, and hacking.'
  },
  {
    icon: mdiLockPlus,
    text: 'Military grade <b>data encryption</b>, mandatory <b>two factor authentication</b> and managed <b>backups</b> are all handled for you as standard.'
  },
  {
    icon: mdiLaptop ,
    text: 'Every design choice we have made focuses on security and compliance with modern standards.'
  }
]

const dataParagraphs = [
  {
    icon: mdiFormatAlignLeft,
    text: 'Store text with full formatting, images, videos, and any other file format you need.'
  },
  {
    icon: mdiFormTextbox,
    text: 'Complete choice over what data you need to collect for your clients with <b>custom fields</b>.'
  },
  {
    icon: mdiContentDuplicate,
    text: 'Save time by creating <b>templates</b> to speed up recording commonly entered information.'
  },
  {
    icon: mdiGavel,
    text: 'A complete <b>audit trail</b> of changes and deletions to data by your users is maintained, keeping you compliant with health record regulations.'
  }
]

const calendarParagraphs = [
  {
    icon: mdiAccountGroup,
    text: '<b>Manage calendars</b> for all your team members.'
  },
  {
    icon: mdiOfficeBuildingMarker,
    text: 'Separate calendars for an <b>unlimited number of locations</b>.'
  },
  {
    icon: mdiEmail,
    text: 'Automatically send <b>email reminders</b> to your clients to reduce missed appointments.'
  },
  {
    icon: mdiCellphoneMessage,
    text: 'Further reduce the chance of last minute no-shows by sending <b>SMS text message reminders</b> to your clients.'
  },
]

</script>

<template>
  <generic-layout>
    <v-row dense class="mb-2">
      <v-col cols="12" lg="8">
        <v-img src="/logo_hero.png" alt="Secure Clinic Logo" eager></v-img>
      </v-col>
      <v-col cols="12" lg="4" class="align-content-center text-right">
        <h1 class="text-h6 text-sm-h4 font-weight-bold lead">Manage appointments and patient data for your clients safely in the cloud.</h1>
      </v-col>
    </v-row>



    <trial-banner></trial-banner>

    <v-row>


      <v-col cols="12" md="6">
        <home-card
            :icon="mdiMagnify"
            color="secondary"
            title="Powerful data tools"
            subtitle="Manage your data in your way."
            :paragraphs="dataParagraphs"
            :action="{label: 'Read more about features', url:'/features/'}">

        </home-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-center">
        <v-img aspect-ratio="16/9" max-height="350px" src="/demo_audit.jpg" class="my-4" alt="A laptop showing the data audit and governance tools built into Secure Clinic medical records software."></v-img>
      </v-col>

      <v-col cols="12" md="6">
        <home-card
            :icon="mdiCalendarMonth"
            :paragraphs="calendarParagraphs"
            color="primary"
            subtitle="Your calendar of appointments for all of your users and locations is fully integrated with your health record"
            title="Manage your appointment diary"
            :action="{label:'Read more about features',url:'/features/'}">
        </home-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-center">
        <v-img aspect-ratio="16/9" max-height="350px" src="/demo_calendar.jpg" class="my-4" alt="The appointment calendar of a user on an iPad and a reminder text message on a phone"></v-img>
      </v-col>

      <v-col cols="12" md="6">
        <home-card
            :icon="mdiCloudLock"
            :paragraphs="securityParagraphs"
            color="orange"
            subtitle="Your clients expect the highest standards when they trust you with their most sensitive data."
            title="Secure by design"
            :action="{label:'Read more about security',url:'/security/'}">
        </home-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-center">
        <v-img aspect-ratio="16/9" max-height="350px" src="/hero.jpg" class="my-4" alt="A healthcare provider showing their patient the Secure Clinic cloud medical record."></v-img>
      </v-col>

    </v-row>

    <trial-banner outlined class="mt-2"></trial-banner>
  </generic-layout>
</template>

<style scoped>
.lead {
  border-left: #01aac1 solid 1rem;
  padding-left: 10px;
}
</style>

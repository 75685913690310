import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {nextTick} from "vue";
import NotFound from "@/views/NotFound.vue";

import NProgress from 'nprogress';
import '../assets/progress.css'


//Sitemap is done manually
//public/sitemap.xml
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: "Secure Clinic | Cloud Electronic Health Record",
            description: "Cloud based electronic medical records for your clients.  Security, backups and logging all handled.  Start free today."}
    },
    {
        path: '/features',
        name: 'features',
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Features.vue'),
        meta: {
            title: "Features",
            description: "Our cloud software is feature rich and scales with your business.  It is built to keep your company compliant, and make your life easier."
        }
    },
    {
        path: '/security',
        name: 'security',
        component: () => import('@/views/Security.vue'),
        meta: {
            title: "Security",
            description: "Protect your data with military grade encryption, two-factor authentication and managed backups."
        }
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import("@/views/Pricing.vue"),
        meta: {
            title: "Pricing",
            description: "Pricing that scales with your business success.  Start now for free"
        }
    },
    {
        path: '/contact-us',
        name: 'contact',
        component: () => import("@/views/ContactUs.vue"),
        meta: {
            title: "Contact Us",
            description: "Get in touch with any queries or requests."
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import("@/views/PrivacyPolicy.vue"),
        meta: {title: "Privacy Policy"}
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import("@/views/TermsAndConditions.vue"),
        meta: {title: "Terms And Conditions"}
    },
    {
        path: '/start-trial',
        name: 'startTrial',
        component: () => import("@/views/StartTrial.vue"),
        meta: {
            title: "Start Your Free Trial",
            description: "Free 30 day trial!  Try all our great features."
        }
    },
    {
        path: '/landing/info-sent/',
        name: 'landingConfirmation',
        component: () => import("@/views/landing/LandingConfirmation.vue"),
        meta: {
            title: "Your trial has been requested",
        }
    },
    {
        path: '/landing/uk-ehr-free-trial/',
        name: 'landingUKTrial',
        component: () => import("@/views/landing/LandingUKTrial.vue"),
        meta: {
            title: "UK Electronic Health Record Free Trial",
            description: "The electronic health record designed for UK independent clinics.  Now available with a free trial."
        }
    },
    {
        path: '/for/doctors',
        name: 'forDoctors',
        component: () => import("@/views/articles/ForDoctors.vue"),
        meta: {
            title: "Electronic Health Records for Doctors",
            description: "Advice and considerations for doctors looking to safely store their clients' medical records when working in the independent sector."
        }
    },
    {
        path: '/for/nurses',
        name: 'forNurses',
        component: () => import("@/views/articles/ForNurses.vue"),
        meta: {
            title: "Electronic Health Records for Nurses",
            description: "Navigating Regulatory Compliance and Patient Data Security: A Guide for UK Nurses."
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {title: "Not Found"}
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0, behavior: "smooth"}
        }
    },
    routes
})

const title = 'Secure Clinic'

function setDescription(description?: string): void {
    let element = document.querySelector('meta[name="description"]')
    if (!element && !description) {
        return
    }
    if (element && !description) {
        element.remove()
        return;
    }
    if (!element) {
        element = document.createElement("meta")
        element.setAttribute('name', 'description')
        document.head.appendChild(element)
    }
    if (description && element) {
        element.setAttribute('content', description)
    }
}

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach((to, from) => {
    NProgress.done()
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = to.meta.title ? to.meta.title + ' | ' + title : title;
        if (typeof to.meta.description === "string") {
            setDescription(to.meta.description)
        } else {
            setDescription();
        }
    })
});

export default router

<script setup lang="ts">
import {useDisplay} from "vuetify";
import {mdiAt, mdiCash, mdiFormatListBulleted, mdiLock, mdiMenu, mdiStarCircle} from "@mdi/js";
import {ref} from "vue";

const { xs, smAndUp } = useDisplay()
const drawer = ref(false)
const nav = [
  {
    route: '/features/',
    label: 'Features',
    icon: mdiFormatListBulleted,
    highlight: false
  },
  {
    route: '/security/',
    label: 'Security',
    icon: mdiLock,
    highlight: false
  },
  {
    route: '/pricing/',
    label: 'Pricing',
    icon: mdiCash,
    highlight: false
  },
  {
    route: '/contact-us',
    label: 'Contact Us',
    icon: mdiAt,
    highlight: false
  },
  {
    route: '/start-trial/',
    label: 'Free Trial',
    icon: mdiStarCircle,
    highlight: true,
  },
]

const secondaryNav: {label: string, href?: string, to?:string, action?: () => void}[] = [
  {
    label: 'Help & Support',
    href: 'https://help.secure.clinic/'
  },
  {
    label: 'Privacy',
    to: '/privacy/'
  },
  {
    label: 'Server Status',
    href: 'https://status.secure.clinic/'
  }
]
</script>

<template>
  <v-app>

    <v-app-bar color="primary" class="px-2">
      <v-row class="overflow-hidden align-center">
        <v-col cols="4" class=""><router-link to="/" class="text-white">
          <img src="/logo_header.svg" alt="Secure Clinic" height="60" class="mt-2">
        </router-link></v-col>
        <v-col cols="8">
          <div v-if="xs" class="d-flex justify-end">
            <v-btn :icon="mdiMenu" text="Open navigation" @click="drawer = !drawer" aria-description="Open navigation"></v-btn>
          </div>
          <div v-else class="d-flex justify-end">
            <span v-for="(item, index) in nav" class="d-flex">
              <router-link :class="['mx-2', 'text-white', 'text-decoration-none',{'font-weight-black highlight':item.highlight}]" :to="item.route">{{item.label}}</router-link>
              <v-divider v-if="index < nav.length -1" vertical class="d-inline border-opacity-100"></v-divider>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="top" temporary>
      <v-list>
        <v-list-item v-for="item in nav" :to="item.route" :prepend-icon="item.icon">{{item.label}}</v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="px-1 px-sm-2 mt-2">
      <v-row>
        <v-col cols="12" lg="10" offset-lg="1">
          <slot></slot>
        </v-col>
      </v-row>
    </v-main>

    <v-footer color="secondary" class="flex-column mt-2">
      <div class="text-center mt-2">
        <span class="text-white" v-for="(item,index) in nav" >
          <router-link :to="item.route" class="text-white text-decoration-none font-weight-bold">{{item.label}}</router-link>
          <v-divider v-if="index < nav.length - 1" color="white" thickness="1" :vertical="smAndUp" class="ma-2 border-opacity-100 d-sm-inline"></v-divider>
        </span>
      </div>
      <div class="text-center mt-2">
        <span class="text-white" v-for="(item,index) in secondaryNav" >
          <router-link v-if="typeof(item.to) === 'string'" :to="item.to" class="text-white text-decoration-none text-subtitle-2">{{item.label}}</router-link>
          <a v-else-if="typeof(item.href) === 'string'" :href="item.href" class="text-white text-decoration-none text-subtitle-2">{{item.label}}</a>
          <a v-else-if="typeof(item.action) === 'function'" @click="item.action" href="#" class="text-white text-decoration-none text-subtitle-2">{{item.label}}</a>
          <v-divider color="white" thickness="1" :vertical="smAndUp" class="ma-2 border-opacity-100 d-sm-inline"></v-divider>
        </span>
        <a href="#" class="cky-banner-element text-white text-decoration-none text-subtitle-2">Cookie Settings</a>
      </div>
      <p class="text-center mt-8 text-white">
        <img src="/logo_header.svg" alt="Secure Clinic Ltd." width="200" class="mb-0"/>
        <p class="text-caption mt-0">
          UK Limited Company 15050934<br/>
          71-75 Shelton Street, Covent Garden, London
        </p>
      </p>
    </v-footer>
  </v-app>
</template>

<style scoped>
.highlight{
  border-top: solid white 1px;
  border-bottom: solid white 1px;
}
</style>
